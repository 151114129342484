

import './styles.scss';
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import oFirmie from './routes/o-firmie';
import produkty from './routes/produkty';
import sektory from './routes/sektory';
import archive from './routes/archive';
import blog from './routes/blog';
import single from './routes/single';
import taxKategorie from './routes/tax-kategorie';
import taxTagi from './routes/tax-tagi';
import kariera from './routes/kariera';
import kontakt from './routes/kontakt';
import kontaktLokalizacje from "./routes/kontakt-lokalizacje";
import searchResults from './routes/search-results';
import srodowisko from './routes/srodowisko';
import singleProdukty from './routes/single-produkty';
import checkBrowser from "../check-browser/scripts";

checkBrowser();
/**
 * Populate Router instance with DOM routes
 * @type {Router} routes - An instance of our router
 */
const routes = new Router({
    /** All pages */
    common,
    /** Home page */
    home,
    /** About Us page, note the change from about-us to aboutUs. */
    oFirmie,
    produkty,
    sektory,
    archive,
    blog,
    single,
    taxKategorie,
    taxTagi,
    kariera,
    kontakt,
    kontaktLokalizacje,
    searchResults,
    srodowisko,
    singleProdukty
});

/** Load Events */

document.addEventListener("DOMContentLoaded", () => {
	routes.loadEvents()
});
