import { addMargin, introductionDesc } from "../util/vendors";
import Siema from "siema";

export default {
    init() {
        // JavaScript to be fired on all pages
          const isPage = document.querySelector("#kontakt-z-lokalizacjami");
          if(!isPage) return;

        const setHeightPlace = () => {
            const contactPlace = document.querySelector(".contact__places");
            const contactPlaces = document.querySelectorAll(".contact__place")[0];
            const placeChildren = contactPlaces.children;
            let child1;
            let child2;

            for (let i = 0; i < placeChildren.length; i++) {
                child1 = placeChildren[0].offsetHeight;
                child2 = placeChildren[1].offsetHeight;
            }

            contactPlace.style.height = `${child1 + child2}px`;

            if (window.innerWidth > 480) {
                contactPlace.style.height = "auto";
            }
        };

        window.addEventListener("resize", setHeightPlace);

        if (window.innerWidth > 768) {
            addMargin(introductionDesc);
        } else {
            setHeightPlace();
        }

        const sectorsBtns = document.querySelectorAll("[data-sector-btn]");

        const sectorsActive = (e) => {
            e.preventDefault();

            for (var i = 0; i < sectorsBtns.length; i++) {
                sectorsBtns[i].classList.remove("contact__btn--active");
            }

            const clickedTab = e.currentTarget;
            clickedTab.classList.add("contact__btn--active");
            const sectorsTabs = document.querySelectorAll("[data-sector]");

            for (i = 0; i < sectorsTabs.length; i++) {
                sectorsTabs[i].classList.remove("contact__details--active");
            }

            const elementReference = e.currentTarget;
            const activeTabID = elementReference.getAttribute("data-sector-btn");
            const activeTab = document.querySelector(`[data-sector="${activeTabID}"`);
            const heightElement = activeTab.offsetHeight;
            contactDetail.style.height = `${heightElement}px`;
            activeTab.classList.add("contact__details--active");
        };

        for (let i = 0; i < sectorsBtns.length; i++) {
            sectorsBtns[i].addEventListener("click", sectorsActive)
        }

        const placesBtns = document.querySelectorAll("[data-place-btn]");

        const placesActive = (e) => {
            e.preventDefault();

            for (let i = 0; i < placesBtns.length; i++) {
                placesBtns[i].classList.remove("contact__btn--select");
            }

            const clickedTab = e.currentTarget;
            clickedTab.classList.add("contact__btn--select");

            const placesTabs = document.querySelectorAll("[data-place-details]");

            const elementReference = e.currentTarget;
            const activeTabID = elementReference.getAttribute("data-place-btn");

            for (i = 0; i < placesTabs.length; i++) {
                placesTabs[i].classList.remove("contact__place--active");
            }

            const activeTab = document.querySelector(`[data-place-details="${activeTabID}"`);
            activeTab.classList.add("contact__place--active");

            const setMarker = (latitude, longitude) => {
                marker = new google.maps.Marker({
                    position: new google.maps.LatLng(latitude, longitude),
                    map: map,
                    icon: `${window.location.origin}/wp-content/themes/trzuskawica/src/images/marker.svg`
                });
            }

            switch (e.currentTarget.getAttribute("data-place-btn")) {
                case "Sitkówka":
                    map.setCenter(new google.maps.LatLng(50.8061501, 20.5580461));
                    setMarker(50.8061501, 20.5580461);
                    break;
                case "Targowica":
                    map.setCenter(new google.maps.LatLng(50.6889921, 16.9641882));
                    setMarker(50.6889921, 16.9641882);
                    break;
            }
        };

        for (var i = 0; i < placesBtns.length; i++) {
            placesBtns[i].addEventListener("click", placesActive)
        }

        const formLabels = document.querySelectorAll(".form-element");

        const removeTitle = () => {
            formLabels.forEach((label) => {
                label.addEventListener("blur", (e) => {
                    e.currentTarget.parentElement.previousElementSibling.classList.remove("animate");
                });
            });
        };

        removeTitle();

        const animateLabel = () => {
            formLabels.forEach((label) => {
                label.setAttribute("autocomplete", "off");
                label.addEventListener("focus", (e) => {
                    e.currentTarget.parentElement.previousElementSibling.classList.add("animate");
                });
            });
        };

        animateLabel();

        /* Kontakt */

        const directSlider = new Siema({
            selector: "#directSlider",
            duration: 200,
            easing: "ease-out",
            perPage: {
                1920: 4.5,
                1440: 3.5,
                550: 2,
                400: 1
            },
            startIndex: 0,
            draggable: window.innerWidth <= 768 ? true : false,
            multipleDrag: false,
            threshold: 20,
            loop: window.innerWidth <= 1360 ? true : false,
            rtl: false,
            onInit: function() {

                /* Ustawianie nazwy branży */

                const setTradeName = (element, target) => {
                    document.querySelector(`.${target}`).textContent = element;
                };

                /* Ustawianie nazwy województwa */

                const setVoivodeshipName = (element, target) => {
                    document.querySelector(`.${target}`).textContent = element;
                };

                /* Ustawianie niestandardowego kursora myszy wraz z etykietą (województwo) */

                const cursorLabel = () => {
                    const label = document.querySelector(".cursor");

                    document.addEventListener("mousemove", (event) => {
                        const { pageX: posX, pageY: posY } = event;
                        label.style.left = `${posX - label.offsetWidth}px`;
                        label.style.top = `${posY - label.offsetHeight}px`;
                    });
                }

                const cursor = document.querySelector(".cursor");
                const directMap = document.querySelector(".direct__map");

                directMap.addEventListener("mouseenter", () => {
                    cursor.classList.add("cursor--is-active");
                    cursorLabel();
                });

                directMap.addEventListener("mouseleave", () => {
                    cursor.classList.remove("cursor--is-active");
                });

                Array.prototype.removeLast = function() {
                    this.length--;
                    return this;
                }

                const directLinks = Array.prototype.slice.call(document.querySelectorAll(".direct__slide")).removeLast();
                const directDetails = document.querySelector(".direct__details");
                const directBars = document.querySelector(".direct__bars");
                const directContact = document.querySelector(".direct__contact");

                directLinks.forEach((directLink) => {
                    directLink.addEventListener("click", (e) => {
                        e.preventDefault();

                        console.log(directLink);

                        setTradeName(e.currentTarget.parentElement.querySelector(".direct__title").textContent, "direct__bar-name--category");
                        directDetails.classList.add("direct__details--is-open");
                        directBars.children[0].classList.add("direct__bar--step-1");

                        /* Ustawienie zdjęcia w tle w zależności od wyboru branży */

                        ACF.branza.forEach((object) => {
                            if (object.nazwa_branzy === document.querySelector(".direct__bar-name--category").textContent) {
                                tradeImage.style.backgroundImage = `url("${object.zdjecie}")`;
                            }
                        });
                    });
                });

                const voivodeships = document.querySelectorAll("[data-voivodeship]");
                const tradeImage = document.querySelector(".column--image");
                const personsBody = document.querySelector(".persons");

                voivodeships.forEach((voivodeship) => {

                    const label = document.querySelector(".cursor__label");

                    voivodeship.addEventListener("click", (e) => {
                        directBars.children[1].classList.add("direct__bar--step-2");
                        setVoivodeshipName(e.currentTarget.dataset.voivodeship, "direct__bar-name--voivodeship");
                        cursor.classList.remove("cursor--is-active");
                        directContact.classList.add("direct__contact--is-open");
                        let voivodeshipName = e.currentTarget.dataset.voivodeship;

                        /* Wyświetlanie danych kontaktowych po spełnieniu określonych warunków */

                        ACF.branza.forEach((object) => {

                            if (object.nazwa_branzy === document.querySelector(".direct__bar-name--category").textContent) {

                                object.kontakt_ze_specjalista.osoba.forEach((person) => {

                                    person.obszar.forEach((area) => {

                                        if (area === voivodeshipName) {

                                            personsBody.innerHTML += `
												<div class="persons__item">
													<p class="persons__name">${person.imie_i_nazwisko}</p>
													<p class="persons__occupation">${person.stanowisko}</p>
													<ul class="places-list persons__links">
														<li class="places-list__item persons__link" style="display: none"><a href="tel:${person.telefon_stacjonarny["url"]}" class="contact__link contact__link--phone">${person.telefon_stacjonarny["title"]}</a></li>
														<li class="places-list__item persons__link"><a href="tel:${person.telefon_komorkowy["url"]}" class="contact__link contact__link--phone">${person.telefon_komorkowy["title"]}</a></li>
														<li class="places-list__item persons__link"><a href="mailto:${person.adres_email["url"]}" class="contact__link contact__link--email">${person.adres_email["title"]}</a></li>
													</ul>
												</div>
											`;
                                        }
                                    });
                                });
                            }
                        });

                        /* Aktywowanie scrollbara na wypadek wystąpienia większej liczby osób */

                        if (personsBody.children.length > 2) {
                            personsBody.classList.add("persons--scrollbar");
                        } else {
                            personsBody.classList.remove("persons--scrollbar");
                        }
                    });

                    voivodeship.addEventListener("mouseenter", (e) => {
                        label.textContent = e.currentTarget.dataset.voivodeship;
                    });
                });

                const bars = document.querySelectorAll(".direct__bar");

                /* Obsługa tabów */

                bars.forEach((bar, index) => {
                    bar.addEventListener("click", () => {
                        personsBody.innerHTML = null;
                        if (index === 0) {
                            directSlider.goTo(0);
                            directDetails.classList.remove("direct__details--is-open");
                            directContact.classList.remove("direct__contact--is-open");
                            bars[0].classList.remove("direct__bar--step-1");
                            bars[1].classList.remove("direct__bar--step-2");
                        } else if (index === 1) {
                            directContact.classList.remove("direct__contact--is-open");
                            bars[1].classList.remove("direct__bar--step-2");
                        }
                    });
                });

                /* Logistyka */

                const logistics = this.innerElements[this.innerElements.length - 1];

                logistics.classList.add("direct__slide--logistics");

            },
            onChange: function() {

                if (window.innerWidth >= 1920) {
                    if (this.currentSlide === 1) {
                        this.goTo(1.5);
                    } else if (this.currentSlide === 0.5) {
                        this.goTo(0);
                    }
                } else if (window.innerWidth >= 1440 && window.innerWidth < 1920) {
                    if (this.currentSlide === 1) {
                        this.goTo(2.5);
                    } else if (this.currentSlide === 1.5) {
                        this.goTo(0);
                    }
                }

                const dots = document.querySelectorAll(".dots--contact .dots__item");

                dots.forEach((slide, i) => {
                    let addOrRemove = i === this.currentSlide ? "add" : "remove";
                    dots[i].classList[addOrRemove]("dots__item--active");
                });
            }
        });

        Siema.prototype.contactDots = function(element) {
            for (let i = 0; i < this.innerElements.length; i++) {
                const navWrapper = document.querySelector(`.${element}`);
                const btn = document.createElement("button");

                btn.classList.add("dots__item");
                btn.addEventListener("click", () => this.goTo(i));

                navWrapper.appendChild(btn);

                const dots = Array.prototype.slice.call(document.querySelectorAll(".dots__item"));
                dots[0].classList.add("dots__item--active");
            }
        };

        directSlider.contactDots("dots--contact");

        const prevSlide = () => {
            directSlider.prev();
        };

        const nextSlide = () => {
            directSlider.next();
        };

        document.querySelector(".slider__btn--prev").addEventListener("click", prevSlide);
        document.querySelector(".slider__btn--next").addEventListener("click", nextSlide);

    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};